import React, { useEffect } from "react"
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import logo from "../images/wp-logo.svg"
import TextPathDrawing from "../components/textPathDrawing";
import FeaturedWork from "../components/featuredWork";
import Textures from "../components/Textures";
import AltHeader from "../components/AltHeader";

const FormContainer = styled(ToastContainer)`

  @media (min-width: 768px) {
    max-width: 400px;
    width: auto;
  }

  .Toastify__toast {
    max-height: 340px;
    overflow-y: scroll;
    color: black;
    border: 2px solid black;
  }

  .Toastify__toast::-webkit-scrollbar {
    width: 5px;
  }
  
  .Toastify__toast::-webkit-scrollbar-thumb {
    background: #c6d7c7;
  }
`

const IndexPage = () => {

  const notify = () => toast(<FeaturedWork close={toast.dismiss}/>, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: false,
    transition: Slide,
    closeOnClick: false,
  });

  useEffect(() => {
    notify();
  }, [])

  const pages = [
    {
      url: "/",
      name: "Home"
    },
    {
      url: "/about",
      name: "About"
    },
    {
      url: "/work",
      name: "Work"
    }
  ]

  return (
    <main>
      <SEO title="Home" />
      <main
        className="min-h-screen w-screen flex flex-col items-center bg-primary">
        <Textures/>
        <AltHeader pages={pages} homePage={true}/>
        <img 
          className="w-8/12 max-w-6xl z-0 mb-auto lg:mt-auto"
          src={logo} 
          alt="Wild Places: An Identities Company"
          css={css`
            @media screen and (max-width: 1023px) {
              margin-top: 30%;
            }
          `
        }/>
        <div className="drawing-wrapper hidden lg:block">
          <TextPathDrawing />
        </div>
          <FormContainer/>
      </main>
    </main>
  )
}

export default IndexPage
