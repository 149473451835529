import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'


export default function FeaturedWork(props) {

  const data = useStaticQuery(graphql`
    query ContentQuery {
       allContentfulCaseStudies(sort: {fields: publishedDate, order: DESC}, limit: 3) {
        nodes {
          title
          slug
          caseImages {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    }
  `)

  let articleData = data.allContentfulCaseStudies.nodes;

  return (
    <div className="bg-white py-2 pl-4 pr-1">
      <div className="flex">
        <p className="text-sm mb-3">
          If you're ready to start a project you can <a href="mailto:andrew@weareoust.co" className="underline">contact us here</a> but in the meantime take a look at some of our favorite clients ;)
        </p>
      </div>
      <ul>
        {articleData.map((article, index) => {
            return (
              <li key={index} style={{marginBottom: index !== articleData.length - 1 ? "0.5em" : "0"}}>
                <div>
                  <h3 className="text-sm font-bold">Featured Case Study: {article.title}</h3>
                  <Link to={`/work/${article.slug}`} className="uppercase underline text-sm">Learn More</Link>
                </div>
                <img 
                  className="w-full mt-2 border-2 border-black object-cover"
                  style={{height: "220px"}}
                  src={article.caseImages[0].fluid.src}
                  alt={article.title}
                />
              </li>
            )
        })}
      </ul>
    </div>
  )
}
